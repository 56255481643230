import { getDomainList } from '@/api/index'
const state = {
  urlList: []
}
const mutations = {
  SET(state, data) {
    for (const i in data) {
      state[i] = data[i]
    }
  }
}

const actions = {
  // 获取域名
  getDomainList({ state, commit }, key) {
    return new Promise(async (resolve, reject) => {
      if (state.urlList.length > 0) {
        resolve(state.urlList[key])
      } else {
        const res = await getDomainList()
        if (res.status == 0) {
          commit('SET', { urlList: res.data })
          resolve(res.data[key])
        }
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
