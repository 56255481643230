<template>
  <div class="copyright">
    <p class="main">
      Copyright © 2018-{{ new Date().getFullYear() }} 贵州赛雷互动科技有限公司 All
      Rights Reserved.
      <el-link
        class="link"
        :underline="false"
        href="https://huodongge-1254430703.cos.ap-guangzhou.myqcloud.com/uploads/image/hdg/20230926-160332_20230926_161203.jpeg"
        target="_blank"
        >增值电信业务经营许可证：黔B2-20200187&nbsp;</el-link
      >
      <el-link
        class="link"
        :underline="false"
        href="http://beian.miit.gov.cn"
        target="_blank"
        >{{ reference }}</el-link
      >
    </p>

    <a
      v-if="filingNumber"
      class="link item"
      :underline="false"
      target="_blank"
      :href="
        'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=' +
        filingNumber
      "
    >
      <img
        src="https://img.huodongge.cn/uploads/image/20230525/d9cac98d058a49587398bd6c934d9e43.png"
      />
      贵公网安备
      {{ filingNumber }}号
    </a>
  </div>
</template>

<script>
export default {
  name: 'Copyright',
  data() {
    const host = window.location.host

    // icp备案号
    let reference = '黔ICP备16003751号-10'
    const org = {
      'saileihudong.vip': '黔ICP备16003751号-5',
      'sshgc1.cn': '黔ICP备16003751号-6',
      'sshgc1.com': '黔ICP备16003751号-7',
      'sshgc.com.cn': '黔ICP备16003751号-8',
      'growth114.com': '黔ICP备16003751号-9',
      'huodongge.cn': '黔ICP备16003751号-10',
      'hdgpt.com': '黔ICP备16003751号-11',
      'hdg.ac.cn': '黔ICP备16003751号-12',
      'hdg114.com': '黔ICP备16003751号-15',
      'hdg360.com': '黔ICP备16003751号-13',
      'hdg360.cn': '黔ICP备16003751号-14',
      'hdgapi.com': '黔ICP备16003751号-16',
      'huodongge.com': '黔ICP备16003751号-17',
    }
    for (const key in org) {
      if (host.indexOf(key) != -1) {
        reference = org[key]
      }
    }

    // 联网备案号
    let filingNumber = ''
    if (host.includes('growth114.com')) filingNumber = 52010202003490
    if (host.includes('sshgc1')) filingNumber = 52010202003491
    if (host.includes('huodongge.cn')) filingNumber = 52010202003699
    if (host.includes('huodongge.com')) filingNumber = 52010202003718
    if (host.includes('hdg360.cn')) filingNumber = 52010202003719

    return {
      reference: reference,
      host: window.location.host,
      filingNumber: filingNumber,
    }
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.copyright {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 1200px;
  background-color: rgb(60, 60, 60);
  line-height: 30px;
  .main {
    // margin: 0 auto;
    // width: 1200px;
    color: rgb(225, 225, 225);
    .link {
      color: rgb(225, 225, 225);
    }
  }
  .item {
    display: flex;
    align-items: center;
    margin-left: 20px;
    height: 100%;
    color: rgb(225, 225, 225);
    img {
      margin-right: 5px;
      width: 18px;
      height: 18px;
      opacity: 0.8;
    }
  }
}
</style>
