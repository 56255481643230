<template>
  <!-- 密码登录 -->
  <div class="password">
    <div class="wechat" @click="$emit('update:status', 3)">
      <span class="wechat-icon"
        ><span class="login-icon iconfont icon-erweima"></span
      ></span>
      <div class="wechat-icon-shelter"></div>
      <div class="wechat-tips">
        <div class="wechat-border">微信扫码登录</div>
      </div>
    </div>
    <div class="main">
      <div class="title">
        <span class="title-item title-current">密码登录</span>
        <span class="title-item" @click="$emit('update:status', 2)"
          >验证码登录</span
        >
      </div>
      <div class="input-item">
        <span class="item-icon iconfont icon-shoujihaoma"></span>
        <input
          class="input"
          v-if="show"
          type="text"
          placeholder="请输入手机号"
          v-model="phone"
          maxlength="11"
          @input="edit"
          @keyup.enter="inputSubmit"
          @focus="changeClass($event, 'add')"
          @blur="changeClass($event, 'del')"
        />
      </div>
      <div class="input-item">
        <span class="item-icon iconfont icon-mima2"></span>
        <input
          class="input"
          :type="passwordType ? 'password' : 'text'"
          placeholder="请输入密码"
          :maxlength="16"
          v-if="show"
          v-model="password"
          @keyup.enter="inputSubmit"
          @focus="changeClass($event, 'add')"
          @blur="changeClass($event, 'del')"
        />
        <span
          class="input-icon"
          v-show="password"
          @click="passwordType = !passwordType"
        >
          <span
            class="password-icon iconfont icon-yincang"
            v-show="passwordType"
          ></span>
          <span
            class="password-icon iconfont icon-xianshi"
            v-show="!passwordType"
          ></span>
        </span>
      </div>
      <div class="operate">
        <span class="right" @click="$emit('update:status', 5)">忘记密码?</span>
      </div>
      <el-button
        class="login"
        v-show="password && phone"
        :loading="loading"
        @click="submit"
        >登录</el-button
      >
      <div
        class="login login-disable"
        v-show="!password || !phone"
        @click="submit"
      >
        登录
      </div>
      <div class="treaty">
        登录即视为同意<span class="treaty-main" @click="goAgreement"
          >《活动阁平台使用协议》</span
        >
      </div>
      <div class="bottom">
        没有账号?
        <span class="register" @click="$emit('update:status', 4)"
          >立即注册</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { checkTel, checkPassWord } from '@/filters/index'
import SvgIcon from '@/components/SvgIcon/index'

export default {
  name: '',
  props: {
    status: Number,
  },
  components: {
    SvgIcon,
  },
  data() {
    return {
      phone: '', //手机号
      password: '', //密码
      loading: false, //登录loading
      passwordType: true, //false 明文 true 密文
      show: false, //解决输入框默认填写账号密码
    }
  },
  mounted() {
    this.show = true
  },
  methods: {
    // 输入框聚焦
    changeClass(e, type) {
      if (type == 'add') {
        e.currentTarget.parentElement.classList.add('input-focus')
      } else {
        e.currentTarget.parentElement.classList.remove('input-focus')
      }
    },
    inputSubmit() {
      if (!this.phone || !this.password) return
      this.submit()
    },
    edit(e) {
      this.phone = this.phone.replace(/[^\d]/g, '')
    },
    async goAgreement() {
      let url = await this.$store.state['common'].domainList.www.url
      window.open(`${url}/vuemerchant/#/agreement`)
    },
    async submit() {
      if (!checkTel(this.phone))
        return this.$message.warning('请输入正确的手机号')
      if (!this.password) return this.$message.warning('请输入密码')
      // if (!checkPassWord(this.password)) return this.$message.warning('密码仅支持输入数字及字母');
      const res = await this.$store.dispatch('common/getTencentCheck')
      if (res.ret) return
      this.loading = true
      try {
        const params = {
          mobile: this.phone,
          password: this.password,
          ticket: res.ticket,
          rand_str: res.randstr,
          source: 'creator',
        }
        const result = await this.$store.dispatch(
          'merchant/passwordLogin',
          params
        )
        if (result.status == 0) {
          this.$emit('success')
          this.$message.success('登录成功')
        }
        this.loading = false
      } catch (error) {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
input {
  background: none;
  outline: none;
  border: none;
}

input::placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #aebac2;
  line-height: 20px;
  font-size: 14px;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
}
.input-focus {
  border: 1px solid #33aaff !important;
  box-shadow: 0px 0px 0px 2px #ebf7ff;
}
.password {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: 56px;
  .wechat {
    position: absolute;
    right: 0;
    top: 0;
    width: 88px;
    height: 88px;
    cursor: pointer;
    &:hover {
      .wechat-icon {
        color: #33aaff;
      }
      .wechat-tips {
        opacity: 1;
      }
    }
    .wechat-icon {
      background-color: #f0f3f5;
      border-radius: 0 10px 0 0;
      display: block;
      width: 100%;
      height: 100%;
      text-align: center;
      line-height: 90px;
      font-size: 30px;
      overflow: hidden;
      color: #72838f;
      transition: color 0.2s;
      .login-icon {
        font-size: 64px;
      }
    }

    .wechat-icon-shelter {
      position: absolute;
      left: -1px;
      top: 0;
      width: 0;
      height: 0;
      border-bottom: 90px solid #fff;
      border-right: 90px solid transparent;
      z-index: 1;
    }
    .wechat-tips {
      position: absolute;
      left: -82px;
      top: 11px;
      z-index: 2;
      opacity: 0;
      transition: opacity 0.3s;
    }

    .wechat-border {
      width: 88px;
      height: 34px;
      border-radius: 6px;
      background-color: #33aaff;
      position: relative;
      color: #fff;
      text-align: center;
      line-height: 34px;
      font-size: 12px;
      &::before,
      &::after {
        position: absolute;
        top: 50%;
        right: -14px;
        transform: translateY(-50%);
        content: '';
        width: 0;
        height: 0;
        border: 8px solid transparent;
        border-left: 8px solid #33aaff;
      }
    }
  }
  .main {
    display: flex;
    flex-direction: column;
    width: 318px;
    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 20px;
      .title-item {
        font-size: 24px;
        color: #72838f;
        cursor: pointer;
        &:first-child {
          margin-right: 40px;
        }
      }
      .title-current {
        color: #0e1234;
        position: relative;
        font-weight: bold;
        &::after {
          content: '';
          position: absolute;
          bottom: -8px;
          left: 50%;
          transform: translateX(-50%);
          width: 40px;
          height: 4px;
          border-radius: 4px;
          background: linear-gradient(90deg, #33aaff 0%, #66c8ff 100%);
        }
      }
    }

    .input-item {
      margin-top: 24px;
      height: 44px;
      overflow: hidden;
      line-height: 44px;
      background: #ffffff;
      border-radius: 6px;
      padding: 10px 0;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      border: 1px solid #cbcbcb;
      position: relative;
      transition: all 0.3s;
      &:hover {
        border: 1px solid #33aaff;
      }

      .item-icon {
        width: 40px;
        flex-shrink: 0;
        text-align: center;
        font-size: 16px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #333;
        &::after {
          content: '';
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          display: block;
          height: 16px;
          border: 1px solid #d8d8d8;
        }
        .item-icon-svg {
          width: 14px;
          height: 14px;
        }
      }
      .input {
        width: 100%;
        height: 100%;
        padding: 20px;
      }
      .input-icon {
        padding-right: 20px;
        height: 44px;
        line-height: 44px;
        display: inline-block;
        text-align: left;
        cursor: pointer;
        .password-icon {
          font-size: 16px;
          color: #333;
        }
      }
    }
    .operate {
      margin-top: 24px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .left {
        color: #1590ff;
        border-bottom: 1px solid #1590ff;
        cursor: pointer;
      }
      .right {
        cursor: pointer;
        color: #40505c;
      }
    }
    .login {
      margin-top: 32px;
      height: 44px;
      background: #33aaff;
      border-radius: 6px;
      text-align: center;
      color: #f7f9fa;
      font-size: 16px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      &:hover {
        background-color: #248bf2;
      }
      &:active {
        background-color: #176de6;
      }
    }
    .login-disable {
      color: #f7f9fa;
      background: #adddff !important;
    }
    .treaty {
      font-size: 12px;
      margin-top: 16px;
      line-height: 12px;
      text-align: center;
      color: #aebac2;

      .treaty-main {
        color: #72838f;
        cursor: pointer;
        font-weight: bold;
      }
    }
    .bottom {
      padding-top: 67px;
      color: #72838f;
      text-align: center;
      .register {
        height: 24px;
        padding: 0 6px;
        line-height: 24px;
        display: inline-block;
        border-radius: 2px;
        color: #33aaff;
        cursor: pointer;
        font-weight: bold;
      }
    }
  }
}
</style>
