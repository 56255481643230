<template>
  <!-- 首页金额 -->
  <div class="amount">
    <ul class="amount-main">
      <li class="item" v-for="(val, i) in amount" :key="i">
        <div class="item-main">
          <div class="number">
            <span
              class="number-item"
              :style="'animation-delay:' + (index + 3) / 10 + 's'"
              v-for="(item, index) in amountNumber[i]"
              :key="index"
            >{{ item }}</span>
            <span
              :style="'animation-delay:' + (amountNumber[i].length + 4) / 10 + 's'"
              class="type"
            >{{ val.unit }}</span>
          </div>
        </div>
        <div class="item-title">
          <span>{{ val.name }}</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { getAmount } from '@/api/index'
export default {
  name: 'Amount',
  data() {
    return {
      amount: [],
      amountNumber: [],
      date: '46541235'
    }
  },
  created() {
    this.getData()
  },
  methods: {
    async getData() {
      const res = await getAmount()
      if (res.status) return
      this.amount = res.data
      this.switchArr(res.data)
      // this.$http.get(getAmount).then(res => {
      //   if (res.data.status) return;
      //   this.amount = res.data.data;
      //   this.switchArr(res.data.data);
      // });
    },
    // 数字转换为数组
    switchArr(data) {
      for (let i = 0; i < data.length; i++) {
        this.amountNumber.push(data[i].count.toString().split(''))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.amount {
  width: 100%;
  padding: 30px 8%;
  margin: 0 auto;
  .amount-main {
    display: flex;
    .item {
      flex: 25%;
      text-align: center;
      position: relative;
      .item-main {
        .number {
          font-size: 48px;
          color: #42a6ef;

          .number-item {
            display: inline-block;
            animation: strength 1s 0.5s ease forwards;
            opacity: 0;
            transform: translateY(200%);
          }
          .type {
            opacity: 0;
            font-size: 16px;
            color: #999;
            vertical-align: top;
            display: inline-block;
            margin-left: 5px;
            animation: strength 1s 0.5s ease forwards;
          }
        }
      }
      .item-title {
        font-size: 16px;
        margin-top: 5px;
        color: #999;
        animation: name 0.6s 1.2s ease forwards;
        opacity: 0;
        transform: translateX(20px);
      }
      &::before {
        content: '';
        display: block;
        position: absolute;
        height: 70%;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        border-right: 1px solid #e5e5e5;
      }
      &:last-child::before {
        border-right: none;
      }
    }
  }
  @keyframes strength {
    0% {
      opacity: 0;
      transform: translateY(200%);
    }
    33% {
      opacity: 1;
      transform: translateY(-10%);
    }
    66% {
      opacity: 1;
      transform: translateY(5%);
    }
    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  }
  @keyframes name {
    0% {
      opacity: 0;
      transform: translateX(20px);
    }
    100% {
      opacity: 1;
      transform: translateX(0%);
    }
  }
}
</style>
