<template>
  <div class="login">
    <BaseBg :status="status" @close="$emit('close')">
      <!-- 左侧展示图 -->
      <LeftImg />
      <div class="right-box">
        <!-- 密码登录 -->
        <PasswordLogin
          v-if="status == 1"
          :status.sync="status"
          @success="loginSuccess"
        />
        <!-- 验证码登录 -->
        <CodeLogin
          v-if="status == 2"
          :status.sync="status"
          @success="loginSuccess"
        />
        <!-- 微信登录 -->
        <WechatLogin
          v-if="status == 3"
          :status.sync="status"
          :oldStatus="oldStatus"
          @success="loginSuccess"
          @binding="binding"
        />
        <!-- 注册 -->
        <Register
          v-if="status == 4"
          :status.sync="status"
          :oldStatus="oldStatus"
          @success="registerSuccess"
        />
        <!-- 找回密码 -->
        <Retrieve
          v-if="status == 5"
          :status.sync="status"
          :oldStatus="oldStatus"
        />
        <!-- 绑定手机号 -->
        <BindingPhone
          v-if="status == 6"
          :status.sync="status"
          :wechatInfo="wechatInfo"
          @success="loginSuccess"
          @reset="reset"
        />
      </div>
    </BaseBg>
  </div>
</template>

<script>
import BaseBg from './components/BaseBg'
import LeftImg from './components/LeftImg'
import PasswordLogin from './components/PasswordLogin'
import CodeLogin from './components/CodeLogin'
import WechatLogin from './components/WechatLogin'
import Register from './components/Register'
import Retrieve from './components/Retrieve'
import BindingPhone from './components/BindingPhone'
export default {
  name: '',
  components: {
    BaseBg,
    LeftImg,
    PasswordLogin,
    CodeLogin,
    WechatLogin,
    Register,
    Retrieve,
    BindingPhone,
  },
  props: {
    state: {
      type: Number,
    },
  },
  data() {
    return {
      status: 1, // 1密码登录 2验证码登录 3微信扫码登录 4注册 5找回密码 6绑定手机号
      oldStatus: 1, //上一个status  微信登录/注册/找回密码做进入源判断
      wechatInfo: {}, //微信登录成功返回信息
    }
  },
  watch: {
    status: function (newValue, oldValue) {
      this.oldStatus = oldValue
    },
  },
  methods: {
    // 登录成功
    loginSuccess() {
      this.$emit('loginSuccess')
    },
    // 绑定成功
    binding(data) {
      this.wechatInfo = data
      this.status = 6
    },
    // 注册成功
    registerSuccess(phone) {
      this.$emit('register', phone)
    },
    // 默认密码提示
    reset(data) {
      this.$emit('reset', data)
    },
  },
}
</script>

<style lang="scss" scoped>
.login {
  position: fixed;
  width: 100%;
  height: 100%;
  font-family: 'Bebas Neue', sans-serif;
  .right-box {
    flex: 1;
  }
}
</style>
