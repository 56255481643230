<template>
  <!-- 首页案例 -->
  <div class="homeCase">
    <div class="homeCase-main">
      <h3 class="title">行业案例</h3>
      <nav>
        <ul class="nav-main">
          <li
            class="nav-item"
            :class="{ 'nav-active': navIndex == index }"
            @mouseenter="changeNav(index)"
            v-for="(item, index) in shopInfo"
            :key="index"
          >{{ item[0].title_name }}</li>
        </ul>
      </nav>
      <transition>
        <main
          v-if="shopList.length"
          :class="mainState ? 'show' : ''"
          @animationend="mainState = false"
        >
          <div class="main-left">
            <img
              class="phone"
              src="https://static.huodongge.cn/www/images/kkmyfedqnmfzldtzlktd.png"
              alt
            />

            <img
              class="shop-img"
              :class="imgState ? 'show' : ''"
              @animationend="imgState = false"
              :src="shopList[shopIndex].img"
            />

            <div
              class="code"
              @mouseenter="cancel"
              @mouseleave="open"
              v-show="shopList[shopIndex].qrcode"
            >
              <div class="qr">
                <vue-qr
                  :text="shopList[shopIndex].qrcode"
                  :margin="0"
                  :size="90"
                  :auto-color="true"
                  :dot-scale="1"
                ></vue-qr>
              </div>
              <span class="code-title">扫描二维码查看</span>
            </div>
          </div>
          <div class="main-right">
            <h3 class="right-title">{{ shopList[shopIndex].title }}</h3>
            <div class="right-content">{{ shopList[shopIndex].desc }}</div>
            <div class="right-footer">
              <img
                class="shop-item"
                :class="{ active: shopIndex == i }"
                @mouseenter="shopSelect(i)"
                v-for="(val, i) in shopList"
                :key="i"
                :src="val.thumb_img"
              />
            </div>
          </div>
        </main>
      </transition>
    </div>
  </div>
</template>

<script>
import VueQr from 'vue-qr'
import { getHomeCase } from '@/api/index'
export default {
  name: 'HomeCase',
  components: {
    VueQr
  },
  data() {
    return {
      // 选中导航栏
      navIndex: 0,
      // 商家列表
      shopList: [],
      // 选中商家
      shopIndex: 0,
      // 商家信息
      shopInfo: [],
      // 计时器
      timer: {},

      // 控制动画状态
      mainState: true,
      imgState: true
    }
  },
  created() {
    this.getCase()
  },
  mounted() {
    this.open()
    this.$once('hook:beforeDestroy', () => {
      clearInterval(this.timer)
    })
  },
  methods: {
    // 案例导航栏选择
    changeNav(index) {
      this.navIndex = index
      this.shopIndex = 0
      this.shopList = this.shopInfo[index]
      this.mainState = true
    },
    // 案例商家选择
    shopSelect(index) {
      this.shopIndex = index
      this.imgState = true
      clearInterval(this.timer)
      this.open()
    },
    // 获取数据
    async getCase() {
      const res = await getHomeCase()
      this.shopInfo = res.data
      this.shopList = this.shopInfo[0]

      // this.$http.get(getHomeCase).then(res => {
      //   this.shopInfo = res.data.data;
      //   this.shopList = this.shopInfo[0];
      // });
    },
    // 鼠标悬停在海报,取消定时器
    cancel() {
      clearInterval(this.timer)
    },
    open() {
      this.timer = setInterval(() => {
        if (this.shopIndex == this.shopList.length - 1) {
          this.shopIndex = 0
          this.imgState = true
          return
        }
        this.shopIndex = this.shopIndex + 1
        this.imgState = true
      }, 5000)
    }
  }
}
</script>

<style lang="scss" scoped>
.homeCase {
  background-color: rgba(246, 246, 246, 0.31);
  width: 100%;

  .homeCase-main {
    width: 1000px;
    margin: 0 auto;
    padding: 80px 0;
    .title {
      margin-bottom: 32px;
      text-align: center;
      font-size: 40px;
      color: #333;
    }
    nav {
      width: 100%;
      .nav-main {
        text-align: center;
        .nav-item {
          font-size: 20px;
          color: #999;
          padding: 15px 0;
          display: inline-block;
          border-bottom: 1px solid hsla(0, 0%, 85%, 0.4);
          width: 120px;
          cursor: pointer;
        }
        .nav-active {
          font-size: 28px;
          color: #409eff;
          border-bottom: 5px solid #409eff;
          padding-bottom: 5px;
          font-weight: 500;
          transition: border 0.4s;
        }
      }
    }
    main {
      display: flex;
      justify-content: space-between;
      min-height: 560px;
      padding-top: 80px;
      padding: 50px;
      .main-left {
        width: 365px;
        position: relative;
        .phone {
          width: 365px;
          z-index: 102;
          position: absolute;
          left: 0;
          top: 0;
        }
        .shop-img {
          position: absolute;
          width: 197px;
          top: 30px;
          left: 35px;
          z-index: 1;
        }
        .code {
          position: absolute;
          width: 197px;
          height: 426px;
          top: 30px;
          left: 35px;
          z-index: 300;
          background-color: rgba(0, 0, 0, 0.5);
          text-align: center;
          opacity: 0;
          transition: opacity 0.3s;

          .qr {
            margin-top: 120px;
          }
          .code-title {
            display: block;
            margin-top: 10px;
            color: #ffffff;
            font-size: 13px;
          }
          &:hover {
            opacity: 1;
          }
        }
      }
      .main-right {
        width: 576px;
        padding-top: 30px;
        .right-title {
          font-size: 43px;
          color: #242424;
          margin-bottom: 20px;
          font-weight: 500;
        }
        .right-content {
          font-size: 18px;
          color: #141414;
          padding-bottom: 22px;
          min-height: 120px;
          overflow: hidden;
        }
        .right-footer {
          margin-top: 30px;
          .shop-item {
            width: 75px;
            height: 75px;
            margin-top: 20px;
            margin-right: 30px;
            display: inline-block;
            object-fit: cover;
            border-radius: 20px;
            cursor: pointer;
          }
          .active {
            border: 2px solid #1696ff;
          }
        }
      }
    }
  }
}
.show {
  animation: showMain 0.3s;
}
@keyframes showMain {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
