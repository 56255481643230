<template>
  <div class="VideoPopup" v-if="show">
    <div class="content" @click="play">
      <img
        src="https://img.huodongge.cn/uploads/image/20250124/11d65127ff74a0d30aebaacc1876b0ac.png"
      />
      <video ref="videoPlayer" autoplay loop>
        <source
          src="https://huodongge-1254430703.cos.ap-guangzhou.myqcloud.com/video/%E9%A3%9E%E4%B9%A620250122-161918.mp4"
          type="video/mp4"
        />
      </video>
      <img
        src="https://img.huodongge.cn/uploads/image/20250124/adb08e017306a76e0994c3e4e0e139de.png"
        class="play"
        v-if="showPlay"
      />
      <div class="close" @click="closePopup">
        <i class="iconfont icon-guanbi"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  components: {},
  data() {
    return {
      show: false,
      showPlay: true,
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      const lastClosed = localStorage.getItem('new_year_video_popup_closed')
      if (!lastClosed || this.isDayPassed(lastClosed)) {
        this.show = true
      }
    },
    play() {
      this.showPlay = false
      this.$refs.videoPlayer.play()
    },
    closePopup() {
      this.show = false
      localStorage.setItem(
        'new_year_video_popup_closed',
        new Date().toISOString()
      )
    },
    isDayPassed(lastClosed) {
      const lastClosedDate = new Date(lastClosed)
      const now = new Date()
      return now.toDateString() !== lastClosedDate.toDateString()
    },
  },
}
</script>

<style lang="scss" scoped>
.VideoPopup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10000;
}
.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 832px;
  height: 602px;
  border-radius: 20px;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
  }
  video {
    position: absolute;
    top: 136px;
    left: 50%;
    transform: translate(-50%);
    width: 800px;
    height: 450px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .play {
    position: absolute;
    height: 160px;
    width: 160px;
    left: 336px;
    top: 252px;
    cursor: pointer;
  }
  .close {
    position: absolute;
    top: 50px;
    right: -48px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .iconfont {
      color: #fff;
    }
  }
}
</style>
