// token-令牌
export const ACCESS_TOKEN = 'token'
// 商户信息
export const MERCHANT_INFO = 'merchantInfo'

// 域名列表
export const DOMAIN_LIST = 'Domain_List'
// 访问时间
export const HOMETIME = 'home_time'
// wechat登陆信息
export const WECHAT_INFO = 'wechat_info'
// 注册完成
export const REGISTER_SUCCESS = 'register_success'
// 活动阁埋点api请求任务信息
export const LOGIN_TASK_API_REQUEST = 'login_task_api_request';