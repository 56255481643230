<template>
  <!-- 注册 -->
  <div class="password">
    <div class="main">
      <div class="title-box">
        <span class="title">{{
          registerType == 1 ? '机构注册' : '个人注册'
        }}</span>
        <span
          class="title-change"
          v-if="registerType == 2 || (registerType == 1 && step == 1)"
          @click="changeRegisterType"
          ><span class="change-icon iconfont icon-qiehuan"></span
          >{{ registerType == 1 ? '个人注册' : '机构注册' }}</span
        >
      </div>
      <div class="main-enterprise" v-if="registerType == 1 && step == 1">
        <div class="input-item">
          <span class="item-icon iconfont icon-jigou"></span>
          <input
            class="input"
            type="text"
            placeholder="请输入机构名称"
            v-model="enterpriseName"
            autocomplete="off"
            @keyup.enter="inputSubmit"
            maxlength="50"
            @focus="changeClass($event, 'add')"
            @blur="changeClass($event, 'del')"
          />
        </div>
        <div class="input-item">
          <span class="item-icon iconfont icon-dianpu"></span>
          <el-select
            v-model="industry"
            class="item-select"
            placeholder="请选择行业"
            size="large"
          >
            <el-option
              v-for="item in industryList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </div>
        <el-button
          class="login"
          v-show="enterpriseName && industry"
          @click="changeStep"
          >下一步</el-button
        >
        <div
          class="login login-disable"
          v-show="!enterpriseName || !industry"
          @click="changeStep"
        >
          下一步
        </div>
      </div>
      <div class="main-account" v-else>
        <div class="input-item">
          <span class="item-icon iconfont icon-shoujihaoma"></span>
          <input
            class="input"
            type="text"
            placeholder="请输入手机号"
            v-model="phone"
            autocomplete="off"
            @input="edit('phone')"
            @keyup.enter="inputSubmit"
            maxlength="11"
            @focus="changeClass($event, 'add')"
            @blur="changeClass($event, 'del')"
          />
        </div>
        <div class="code">
          <div class="input-item code-input">
            <span class="item-icon iconfont icon-yanzheng"></span>
            <input
              class="input"
              type="text"
              placeholder="验证码"
              v-model="code"
              autocomplete="off"
              @input="edit('code')"
              @keyup.enter="inputSubmit"
              maxlength="4"
              @focus="changeClass($event, 'add')"
              @blur="changeClass($event, 'del')"
            />
          </div>
          <el-button
            class="code-btn"
            :class="phone && phone.length == 11 ? '' : 'input-default'"
            type="primary"
            round
            v-show="count <= 0"
            :loading="loading"
            @click="getCode"
            >{{ loading ? '' : '获取验证码' }}</el-button
          >
          <div class="code-default" v-show="count > 0">
            已发送 ({{ count }}S)
          </div>
        </div>
        <div class="input-item">
          <span class="item-icon iconfont icon-mima2"></span>
          <input
            class="input"
            :type="passwordType ? 'password' : 'text'"
            placeholder="请设置6-15位密码"
            v-model="password"
            autocomplete="new-password"
            @keyup.enter="inputSubmit"
            :maxlength="16"
            @focus="changeClass($event, 'add')"
            @blur="changeClass($event, 'del')"
          />
          <span
            class="input-icon"
            v-show="password"
            @click="passwordType = !passwordType"
          >
            <span
              class="password-icon iconfont icon-yincang"
              v-show="passwordType"
            ></span>
            <span
              class="password-icon iconfont icon-xianshi"
              v-show="!passwordType"
            ></span>
          </span>
        </div>
        <el-button
          class="login"
          v-show="password && phone && code"
          :loading="submitLoading"
          @click="submit"
          >注册并登录</el-button
        >
        <div
          class="login login-disable"
          v-show="!password || !phone || !code"
          @click="submit"
        >
          注册并登录
        </div>
        <div class="treaty">
          注册即视为同意<span class="treaty-main" @click="goAgreement"
            >《活动阁平台使用协议》</span
          >
        </div>
      </div>

      <div class="bottom">
        已有账号?<span class="register" @click="openTips">直接登录</span>
      </div>
    </div>
    <RegisterTips :show.sync="tipsShow" @back="backLogin" />
  </div>
</template>

<script>
import RegisterTips from './RegisterTips'
import { checkTel, checkPassWord } from '@/filters/index'
import SvgIcon from '@/components/SvgIcon/index'

export default {
  name: '',
  props: {
    status: Number,
    oldStatus: Number,
  },
  components: {
    SvgIcon,
    RegisterTips,
  },
  data() {
    return {
      phone: '', //手机号
      password: '', //密码
      code: '', //验证码
      loading: false, //获取验证码loading
      count: 0, //倒计时秒
      codeTime: '', //倒计时计时器
      read: false, //true 协议已读
      submitLoading: false, //提交loading
      passwordType: true, //false 明文 true 密文
      tipsShow: false, //挽留弹窗
      enterpriseName: '', //机构名称
      industry: '', //行业
      industryList: [], //行业大类
      registerType: 1, //注册类型 1-机构注册 2-个人注册
      step: 1, //注册步骤 机构注册时分2步
    }
  },
  created() {
    this.getIndustry()
  },
  methods: {
    async getIndustry() {
      this.industryList = await this.$store.dispatch('common/getBusinessList')
    },
    openTips() {
      this.tipsShow = true
      console.log('执行')
      console.log(this.tipsShow)
    },
    backLogin() {
      this.tipsShow = false
      setTimeout(() => {
        this.$emit('update:status', this.oldStatus)
      }, 200)
    },
    edit(type) {
      if (type == 'phone') {
        this.phone = this.phone.replace(/[^\d]/g, '')
      }
      if (type == 'code') {
        this.code = this.code.replace(/[^\d]/g, '')
      }
    },
    changeClass(e, type) {
      if (type == 'add') {
        e.currentTarget.parentElement.classList.add('input-focus')
      } else {
        e.currentTarget.parentElement.classList.remove('input-focus')
      }
    },
    // 切换注册类型
    changeRegisterType() {
      this.registerType = this.registerType == 1 ? 2 : 1
      this.phone = ''
      this.password = ''
      this.code = ''
      this.count = 0
      this.enterpriseName = ''
      this.industry = ''
    },
    // 点击下一步
    changeStep() {
      if (!this.enterpriseName) return this.$message.warning('请输入机构名称')
      if (!this.industry) return this.$message.warning('请先选择所属行业')
      this.step = 2
    },
    // 获取验证码
    async getCode() {
      if (!this.phone || !checkTel(this.phone))
        return this.$message.warning('请输入正确的手机号')
      if (this.count) return
      // 验证腾讯防水墙
      const res = await this.$store.dispatch('common/getTencentCheck')
      if (res.ret) return
      this.loading = true
      try {
        // 获取验证码
        const result = await this.$store.dispatch('common/getCode', {
          number: this.phone,
          cms_type: 0,
          ticket: res.ticket,
          rand_str: res.randstr,
        })
        this.count = 0
        if (this.codeTime) clearInterval(this.codeTime)
        this.count = 60
        this.codeTime = setInterval(() => {
          this.count = this.count - 1
          if (this.count <= 0) {
            clearInterval(this.codeTime)
          }
        }, 1000)
        this.loading = false
      } catch (error) {
        this.loading = false
      }
    },
    async goAgreement() {
      this.read = true
      let url = await this.$store.state['common'].domainList.www.url
      window.open(`${url}/vuemerchant/#/agreement`)
    },
    // 输入框按下回车
    inputSubmit() {
      if (!this.phone || !this.code || !this.password) return
      this.submit()
    },
    // 注册
    async submit() {
      if (!checkTel(this.phone))
        return this.$message.warning('请输入正确的手机号')
      if (!this.code || this.code.length < 4)
        return this.$message.warning('请输入正确的验证码')
      if (!this.password) return this.$message.warning('请输入需要设置的密码')
      if (this.registerType == 1) {
        if (!this.enterpriseName) return this.$message.warning('请输入机构名称')
        if (!this.industry) return this.$message.warning('请先选择所属行业')
      }
      // if (!checkPassWord(this.password)) return this.$message.warning('密码仅支持输入数字及字母')
      // if (!this.read) return this.$message.warning('请先阅读并同意平台协议');

      this.submitLoading = true
      try {
        let params = {
          mobile: this.phone,
          code: this.code,
          password: this.password,
          source: this.registerType == 1 ? 'merchant' : 'creator',
        }
        if (this.registerType == 1) {
          params.merchant_name = this.enterpriseName
          params.business = this.industry
        }
        const result = await this.$store.dispatch(
          'merchant/userRegister',
          params
        )
        if (result.status == 0) {
          this.$emit('success', this.phone)
        }
        this.submitLoading = false
      } catch (error) {
        this.submitLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
input {
  background: none;
  outline: none;
  border: none;
}

input::placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #aebac2;
  line-height: 20px;
  font-size: 14px;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
}
.input-focus {
  border: 1px solid #33aaff !important;
  box-shadow: 0px 0px 0px 2px #ebf7ff;
}
.input-default {
  background: #adddff !important;
  border: 1px solid #adddff !important;
}
.password {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: 56px;

  .main {
    display: flex;
    flex-direction: column;
    width: 318px;
    .title-box {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        font-size: 24px;
        color: #0e1234;
        font-weight: 700;
      }
      .title-change {
        color: #33aaff;
        font-size: 14px;
        cursor: pointer;
        font-weight: bold;
        user-select: none;
        .change-icon {
          padding-right: 6px;
        }
      }
    }

    .input-item {
      margin-top: 24px;
      height: 44px;
      overflow: hidden;
      line-height: 44px;
      background: #ffffff;
      border-radius: 6px;
      padding: 10px 0;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      border: 1px solid #cbcbcb;
      position: relative;
      transition: all 0.3s;
      &:hover {
        border: 1px solid #33aaff;
      }

      .item-icon {
        width: 40px;
        flex-shrink: 0;
        text-align: center;
        font-size: 16px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #333;
        &::after {
          content: '';
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          display: block;
          height: 16px;
          border: 1px solid #d8d8d8;
        }
        .item-icon-svg {
          width: 14px;
          height: 14px;
        }
      }
      .input {
        width: 100%;
        height: 100%;
        padding: 20px;
      }
      .input-icon {
        padding-right: 20px;
        height: 44px;
        line-height: 44px;
        display: inline-block;
        text-align: left;
        cursor: pointer;
        .password-icon {
          font-size: 16px;
          color: #333;
        }
      }
    }
    .code {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 24px;
      .code-input {
        margin-top: 0;
        margin-right: 8px;
      }
      .code-btn {
        padding: 14px 19px;
        width: 108px;
        height: 44px;
        border-radius: 6px;
        background-color: #33aaff;
        color: #f7f9fa;
        &:hover {
          background-color: #248bf2;
        }
        &:active {
          background-color: #176de6;
        }
        /deep/.el-icon-loading {
          margin-left: 6px;
        }
      }
      .code-default {
        width: 110px;
        height: 44px;
        border-radius: 6px;
        background-color: #adddff;
        color: #f7f9fa;
        text-align: center;
        line-height: 44px;
      }
    }
    .login {
      margin-top: 32px;
      width: 100%;
      height: 44px;
      background: #33aaff;
      border-radius: 6px;
      text-align: center;
      color: #f7f9fa;
      font-size: 16px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border:none;
      &:hover {
        background-color: #248bf2;
      }
      &:active {
        background-color: #176de6;
      }
    }
    .login-disable {
      color: #f7f9fa;
      background: #adddff !important;
    }

    .treaty {
      font-size: 12px;
      margin-top: 24px;
      line-height: 12px;
      text-align: center;
      color: #aebac2;

      .treaty-main {
        color: #72838f;
        cursor: pointer;
        font-weight: bold;
      }
    }
    .bottom {
      padding-top: 45px;
      color: #72838f;
      text-align: center;
      .register {
        height: 24px;
        padding: 0 6px;
        line-height: 24px;
        display: inline-block;
        border-radius: 2px;
        color: #33aaff;
        cursor: pointer;
        font-weight: bold;
      }
    }
  }
  .main-enterprise {
    .input-item {
      .item-select {
        padding-left: 6px;
        width: 100%;
        font-size: 14px;
        /deep/.el-input__inner {
          border: none;
        }
        /deep/.el-input__inner::placeholder {
          color: #aebac2;
        }
      }
    }
    .login {
      margin-bottom: 104px;
    }
  }
}
</style>
