/*
 * @Description:图片地址存储
 *  命名约定(非必须)：页面路径+下划线+页面内命名
 * @Date: 2021-10-27 17:07:58
 * 所有 img.huodongge.cn 都需要替换为 huodongge-1254430703.cosgz.myqcloud.com  (加速域名)
 *
 */
export const imagesUrl = {
	// 主页店铺图 未登录
	home_noneshop: 'https://huodongge-1254430703.cosgz.myqcloud.com/uploads/image/20211027/75c20d01550787c01d9340853ff7bbfd.png',
	// 主页店铺图 已登录
	home_haveshop: 'https://huodongge-1254430703.cosgz.myqcloud.com/uploads/image/20211028/392804dc40a54322d4d24701d3bbbfb1.png',
	// 主页 活动推荐日历 图片暂存
	home_recommendCalendar: 'https://huodongge-1254430703.cosgz.myqcloud.com/uploads/image/20211115/e240304958c72e690d7c182a502a1160.png',
	// 缺省页-暂无内容
	defaultPage_noContent: 'https://huodongge-1254430703.cosgz.myqcloud.com/uploads/image/20211123/6d16d8c95141f99a732821f2a080eb86.png',
	// 缺省页-暂无收藏
	defaultPage_noCollect: 'https://huodongge-1254430703.cosgz.myqcloud.com/uploads/image/20211123/8d1030984a9e813311116a30c20c3016.png',
	// 缺省页-暂无信息
	defaultPage_noMessage: 'https://huodongge-1254430703.cosgz.myqcloud.com/uploads/image/20211123/642aea0765dcdbe42f39be726cc7aaf1.png',
	// 缺省页-暂无搜索信息
	defaultPage_noData: 'https://huodongge-1254430703.cosgz.myqcloud.com/uploads/image/20211123/65bfec8e5b6572ba72b2d91681abfc04.png',
	// 合作创作背景
	creator_bg: 'https://huodongge-1254430703.cosgz.myqcloud.com/uploads/image/20211123/578149a9020440371cf9f56319adb1fc.png',
	// 头像-默认头像
	head_default: 'https://huodongge-1254430703.cosgz.myqcloud.com/uploads/image/20211122/40b1cd3c0c3c02487ab4ffec8e35cdc5.png',
	// logo-默认logo
	logo_default: 'https://img.huodongge.cn/uploads/image/20230316/2f0aaa6f7594534325ea565ea342f05c.png'
};

// 登录- 插画
export const loginUrl = {
	// 登录-左侧海报
	login_poster: 'https://img.huodongge.cn/uploads/image/20230517/8ed67884846c53440cadd8292dcc52aa.png',
	// 登录-默认密码提示
	login_password: 'https://huodongge-1254430703.cosgz.myqcloud.com/uploads/image/20211111/3df7d889b8c1428f7c4341a40943ab64.png',
	// 登录-注册成功
	login_register_success: 'https://huodongge-1254430703.cosgz.myqcloud.com/uploads/image/20211111/781a5c0a467eb46410b9aaf9e68695ef.png',
	// 登陆-注册挽留-背景
	login_register_tips_bg: 'https://huodongge-1254430703.cos.ap-guangzhou.myqcloud.com/uploads/image/20220323/1648024537phpZAnoKi.png',
	// 登陆-注册挽留-表情
	login_register_tips: '//huodongge-1254430703.cos.ap-guangzhou.myqcloud.com/uploads/image/20220323/1648024630php7iY5tc.png',
	// 登录-重置密码成功
	login_retrieve_success: 'https://huodongge-1254430703.cosgz.myqcloud.com/uploads/image/20211111/3bc864c4a4a4b997dbda6efa9f092461.png',
};
