<template>
  <div class="login" v-if="show">
    <!-- 登录弹窗 -->
    <Login
      v-if="state == 1"
      :state.sync="state"
      @close="close"
      ref="login"
      @loginSuccess="loginSuccess"
      @reset="reset"
      @register="register"
    />
  </div>
</template>

<script>
import Login from './login'
export default {
  name: '',
  components: {
    Login,
  },
  data() {
    return {
      state: 1, // 1登录弹窗 2设置密码弹窗 3注册成功
      password: '', //默认密码
      phone: '' //注册的phone ,注册成功获取二维码用
    }
  },
  created() {
    // 初始化腾讯防火墙
    this.$store.dispatch('common/getTencentCheck', true)
    this.$store.dispatch('common/getDomain')
  },
  computed: {
    //弹窗显示
    show() {
      return this.$store.state.merchant.isShowLogin
    }
  },
  watch: {
    show: function(newValue, oldValue) {
      if (newValue) {
        if (this.$store.state['merchant'].isRegister) {
          setTimeout(() => {
            this.$refs.login.status = 4
          })
        } else {
          setTimeout(() => {
            this.$refs.login.status = 1
          })
        }
      }
    }
  },
  methods: {
    close() {
      this.$store.commit('merchant/SET', {
        isShowLogin: false
      })
    },
    register(phone) {
      this.state = 3
      this.phone = phone
    },
    reset(data) {
      this.state = 2
      this.password = data
    },
    loginSuccess() {
      this.$store.commit('merchant/SET', {
        isShowLogin: false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  z-index: 300;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(14, 18, 52, 0.3);
}
</style>
