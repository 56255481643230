<template>
  <div class="official">
    <div class="main">
      <div class="main-left">
        <dl class="left-item">
          <dt>合作联系</dt>
          <dd>商家在线支持</dd>
          <dd>商务合作：bd@huodongge.cn</dd>
          <dd>渠道合作：fkf@huodongge.cn</dd>
          <dd>媒体采访：wx@huodongge.cn</dd>
        </dl>
        <dl class="left-item">
          <dt>服务条款</dt>
          <dd>《隐私政策》</dd>
          <dd>《活动阁商家使用协议》</dd>
          <dd>《活动阁在线报名协议》</dd>
        </dl>
        <dl class="left-item">
          <dt>关于我们</dt>
          <dd>我们是谁</dd>
          <dd>活动阁团队</dd>
          <dd>发展历程</dd>
          <dd>企业文化</dd>
        </dl>
      </div>
      <div class="main-right">
        <h3 class="title">0851-88508746</h3>
        <span class="suntitle">周一至周五 10:00-18:00</span>
        <div class="code">
          <div class="code-item" style="margin-right:30px;">
            <img class="qr" src="https://static.huodongge.cn/www/images/u72.jpg" />
            <span class="qr-title">关注活动阁商家助手</span>
          </div>
          <div class="code-item">
            <img class="qr" src="https://static.huodongge.cn/www/images/u73.jpg" />
            <span>关注活动阁商家学院</span>
          </div>
        </div>
      </div>
    </div>
    <div class="links">
      <span>友情链接:</span>
      <ul class="links-main">
        <li>
          <el-link :underline="false" href="https://cloud.tencent.com" target="_blank">腾讯云</el-link>
        </li>
        <li>
          <el-link :underline="false" href="https://pay.weixin.qq.com" target="_blank">微信支付</el-link>
        </li>
        <li>
          <el-link :underline="false" href="https://www.chuangkit.com" target="_blank">创客贴</el-link>
        </li>
        <li>
          <el-link :underline="false" href="https://www.oceanengine.com" target="_blank">巨量引擎</el-link>
        </li>
        <li>
          <el-link :underline="false" href="https://www.iflytek.com" target="_blank">科大讯飞</el-link>
        </li>
        <li>
          <el-link :underline="false" href="https://www.kaolamedia.com" target="_blank">考拉新媒体</el-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Official',
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.official {
  width: 1200px;
  margin: 0 auto;
  background-color: #fff;
  padding-top: 88px;

  .main {
    display: flex;
    justify-content: space-between;
    .main-left {
      padding-top: 50px;
      display: flex;

      .left-item {
        margin-right: 70px;
        dt {
          font-size: 20px;
          font-weight: 400;
          display: block;
          margin-bottom: 30px;
        }
        dd {
          font-size: 14px;
          line-height: 30px;
          color: rgb(94, 94, 94);
          cursor: pointer;
          &:hover {
            color: #2a82e4;
          }
        }
      }
    }
    .main-right {
      padding-right: 70px;
      .title {
        font-weight: 700;
        font-size: 32px;
      }
      .suntitle {
        line-height: 50px;
      }
      .code {
        display: flex;

        .code-item {
          text-align: center;
          .qr {
            width: 150px;
            height: 150px;
            display: block;
            margin-bottom: 15px;
          }
        }
      }
    }
  }
  .links {
    display: flex;
    line-height: 50px;
    color: rgb(94, 94, 94);

    .links-main {
      display: flex;

      li {
        margin-left: 15px;
        cursor: pointer;
      }
    }
  }
}
</style>
