<template>
  <div id="map" class="map" v-if="show">
    <div class="map-text">活动阁商家全国分布</div>
    <div class="btn">
      <div class="btn-lt" @click="zoomIn">+</div>
      <div class="btn-rt" @click="zoomOut">-</div>
    </div>
  </div>
</template>

<script>
import { getShopLocation } from '@/api/index'
export default {
  created() {
    // 更新地图
    this.init()
  },
  data() {
    return {
      zoom: 5,
      show: true,
      map: null
    }
  },
  methods: {
    /**
     * 初始化地图
     */
    async init() {
      // 拉取服务端数据
      const { data } = await getShopLocation()

      this.map = new T.Map('map')
      this.map.centerAndZoom(new T.LngLat(108.95, 34.27), 5)
      this.map.setStyle('indigo')
      this.map.disableScrollWheelZoom()

      data.forEach(item => {
        if (!item.shop_cover || !item.lon || !item.lat || !item.shop_name)
          return
        const icon = new T.Icon({
          iconUrl: item.shop_cover,
          iconSize: new T.Point(36, 36),
          iconAnchor: new T.Point(10, 10),
          className: 'my-icon-class' // CSS类名
        })
        const marker = new T.Marker(new T.LngLat(item.lon, item.lat), {
          icon: icon
        })

        // mouseover
        marker.addEventListener('click', e => {
          this.map.openInfoWindow(
            new T.InfoWindow(item.shop_name, { offset: new T.Point(10, 0) }),
            e.lnglat
          ) //开启信息窗口
        })

        this.map.addOverLay(marker)
      })
    },
    // 放大
    zoomIn() {
      this.map.zoomIn()
    },
    // 缩小
    zoomOut() {
      this.map.zoomOut()
    }
  }
}
</script>

<style lang="scss">
#map {
  width: 100%;
  height: 800px;
  position: relative;
  .amap-logo {
    display: none !important;
  }
  .map-text {
    position: absolute;
    top: 35px;
    left: 35px;
    z-index: 99;
    font-size: 20px;
    color: #ccc;
  }
  .btn {
    position: absolute;
    bottom: 35px;
    right: 35px;
    z-index: 99;
    font-size: 20px;
    color: #aaa;
    background-color: #fff;
    width: 40px;
    border-radius: 4px;
    cursor: pointer;
    .btn-lt,
    .btn-rt {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s;
      border-radius: 4px;
      &:last-child {
        border-top: 1px solid #eee;
      }
      &:hover {
        background-color: #f5f5f5;
        color: #666;
      }
    }
  }
}
.item-logo {
  width: 40px;
  height: 40px;
  position: relative;
  border-radius: 50%;
  border: 4px solid rgba(236, 236, 236, 0.1);
  .item-proup {
    white-space: nowrap;
    background-color: rgba(136, 136, 136, 0.5);
    color: #fff;
    position: absolute;
    z-index: 9;
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 2px;
    bottom: calc(100% + 10px);
    left: 50%;
    transform: translateX(-50%);
    display: none;
    &:after {
      content: '';
      position: absolute;
      left: 50%;
      top: 100%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid rgba(136, 136, 136, 0.5);
    }
  }
  &:hover .item-proup {
    display: block;
  }
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  .item-name {
    color: #fff;
    font-size: 0.8em;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.tdt-pane {
  z-index: 1 !important;
}
.tdt-touch .tdt-bar,
.tdt-touch .tdt-control-copyright,
.tdt-touch .tdt-control-layers {
  display: none !important;
}
.my-icon-class {
  border: 4px solid rgba(236, 236, 236, 0.1);
  border-radius: 50%;
}
</style>
