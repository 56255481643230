import axios from "axios";
import qs from "qs";
import { Message } from 'element-ui'

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 30000 // 请求超时时间
});

// 异常拦截处理器
const errorHandler = error => {
  return Promise.reject(error);
};

// request
request.interceptors.request.use(config => {
  // const token = storage.get(ACCESS_TOKEN);
  // if (token) {
  //   data["token"] = token;
  // }
  return config;
}, errorHandler);

// response
request.interceptors.response.use(response => {
  // if (response.data.status === -1) {
  //   return store.dispatch("Logout").then(() => {
  //     setTimeout(() => {
  //       window.location.reload();
  //     }, 1500);
  //   });
  // }
  if (response.data.status) {
    Message.error({
      message: response.data.message
    })
  }
  return response.data;
}, errorHandler);

const getHeaders = {
  headers: { "Content-Type": "application/json" }
};
const postHeaders = {
  headers: { "Content-Type": "application/x-www-form-urlencoded" }
};

// 定义对外Get、Post请求
export default {
  get(url, data = {}, headers = getHeaders) {
    return request.get(url, { params: data }, headers);
  },
  post(url, data = {}, headers = postHeaders) {
    return request.post(url, qs.stringify(data), headers);
  },
};
