import request from './request'

// 案例-获取所有案例活动
export const getActiveAll = data => request.get('/active/all', data)
// 公用-获取验证码
export const postMerchantLoginCms = data => request.post('/merchant/login/cms', data)
// 公共-获取机构行业大类
export const getBusinessPicture = (data) => request.get('/web-hdg-user/hdg_user/business/picture', data);

//商户-校验用户是否有平台身份
export const getCheckActionUser = (data) => request.get('/web-hdg-user/hdg_user/check_action_user', data);
//商户-同步用户身份
export const getSyncUserCard = (data) => request.get('/web-hdg-user/hdg_user/sync/user/card', data);
//商户-获取商户基本信息
export const getMerchantInfo = (data) => request.get('/web-hdg-user/hdg_user/user_info', data);
//商户-密码登录
export const postMerchantPasswordLogin = (data) => request.post('/web-hdg-user/hdg_user/login', data);
//商户-退出登录
export const getLoginOut = (data) => request.get('/web-hdg-user/hdg_user/login_out', data);
//商户-验证码登录
export const postMerchantCodeLogin = (data) => request.post('/web-hdg-user/hdg_user/code_login', data);
//商户-注册
export const postMerchantRegister = (data) => request.post('/web-hdg-user/hdg_user/register', data);
// //商户-找回密码
export const postForgetMerchantPassword = (data) => request.post('/web-hdg-user/hdg_user/forget_password', data);


//登录-获取微信登录二维码
export const getLoginQrCode = (data) => request.get('/web-hdg-user/hdg_user/make/login/qrCode', data);
//登录-获取用户是否登录
export const getScanQrcode = (data) => request.get('/web-hdg-user/hdg_user/scan/Qrcode', data);

// 首页-轮播图
export const getCarousel = data => request.get('/official/carousel', data)
// 首页-案例
export const getHomeCase = data => request.get('/official/case', data)
// 首页-数据
export const getAmount = data => request.get('/official/site/data', data)
// 获取活动详情
export const getActiveRecommendDetail = data =>
  request.get('/active/recommend/detail', data)
// 首页-获取排名前100商家
export const getShopLocation = data => request.get('/get/shop/location', data)

// 公共-获取所有域名
export const getDomainList = data => request.get('/domain/list', data)
// 公共-退出登陆
export const getMerchantLoginOut = data =>
  request.get('merchant/login/out', data)
