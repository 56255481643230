import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/fonts/iconfont.js'
import { Message } from 'element-ui'
import Cookies from 'js-cookie'
import VueMatomo from 'vue-matomo'

Vue.use(ElementUI)
Vue.config.productionTip = false
Vue.prototype.$message = Message

// matomo 数据分析 - 开始
try {
  if (process.env.VUE_APP_IS_MATOMO) {
    const merchantInfo = JSON.parse(Cookies.get('merchantInfo') || '{}')
    const matomoData = {
      host: '//matomo.huodongge.cn/',
      siteId: 2,
      router: router,
      enableLinkTracking: true, // 对常规链接启用链接跟踪。请注意，这不会, 用于路由链接（即内部Vue路由器链接）
      trackInitialView: true, // 是否跟踪初始页面视图
      debug: false
    }
    if (merchantInfo.merchant_nickname)
      matomoData['userId'] = merchantInfo.merchant_nickname // 可选，如果你有用户ID
    Vue.use(VueMatomo, matomoData)
  }
} catch (error) {}
// matomo 数据分析 - 结束

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
