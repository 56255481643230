<template>
  <!-- 登录背景 -->
  <div class="baseBg">
    <div class="main">
      <!-- 关闭按钮 -->
      <div class="close" v-if="status != 6" @click="$emit('close')">
        <span class="close-icon iconfont icon-guanbi"></span>
      </div>
      <div class="slot-main">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    status: Number,
  },
  data() {
    return {}
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.baseBg {
  position: fixed;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);

  .main {
    position: relative;
    background-color: #fff;
    width: 852px;
    min-height: 516px;
    filter: drop-shadow(0px 10px 20px rgba(14, 18, 52, 0.15));
    border-radius: 16px;
    box-sizing: border-box;
    animation: show 0.5s ease;
    @keyframes show {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    .close {
      position: absolute;
      right: -52px;
      top: 0;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: rgba(255, 255, 255, 0.2);
      .close-icon {
        color: #fff;
        font-size: 18px;
      }
    }
    .slot-main {
      display: flex;
      height: 100%;
    }
  }
}
</style>
